<template>
  <v-container>
    <v-row>
      <GoBackBtn :routeToGo="routeToGo" :tabId="false" />
    </v-row>
    <PageHeader :title="title" />
    <v-data-table
      class="elevation-1"
      :headers="headers"
      show-expand
      :expanded.sync="expanded"
      :items="registrosAsignacion"
      item-key="procAsignTablaFacId"
      :search="searchAsig"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-row>
            <v-col cols="10">
              <v-text-field
                v-model="searchAsig"
                :append-icon="searchIcon"
                label="Buscar"
                single-line
                hide-details
              ></v-text-field>
            </v-col>
          </v-row>
        </v-toolbar>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              v-bind="attrs"
              size="20"
              v-on="on"
              @click="verProcesoDeta(item)"
            >
              {{ verProc }}
            </v-icon>
          </template>
          <span>Ver proceso</span>
          <!-- cambiar por ver -->
        </v-tooltip>
        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              v-bind="attrs"
              size="20"
              :disabled="item.usuAnula != null"
              v-on="on"
              @click="openDialogAnulaProceso(item)"
            >
              {{ anuladoIcon }}
            </v-icon>
          </template>
          <span>Anular proceso</span>
        </v-tooltip>
      </template>
      <template v-slot:[`item.data-table-expand`]="{ item, isExpanded }">
        <v-tooltip right>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              @click="handleExpansion(item, isExpanded)"
              v-bind="attrs"
              size="20"
              v-on="on"
              >{{ isExpanded ? "mdi-account-check" : "mdi-account" }}</v-icon
            >
          </template>
          <span>Usuarios</span>
        </v-tooltip>
      </template>
      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length">
          <v-row>
            <v-col cols="12" md="3">
              <strong>Filtros aplicados:</strong>
              {{ item.filtrosAplicados }}
            </v-col>
            <v-col cols="12" md="3">
              <strong>Fecha alta:</strong>
              {{ item.fecAlta }}
            </v-col>
            <v-col cols="12" md="3">
              <strong>Usuario alta:</strong>
              {{ item.usuAlta }}
            </v-col>
            <v-col cols="12" md="3">
              <strong>Usuario anula:</strong>
              {{ item.usuAnula }}
            </v-col>
            <v-col cols="12" md="3">
              <strong>Fecha anula:</strong>
              {{ item.fecAnula }}
            </v-col>
          </v-row>
        </td>
      </template>
    </v-data-table>
    <v-dialog
      v-if="modalVerTablasAnular"
      v-model="modalVerTablasAnular"
      @keydown.esc="closeAndReload"
      max-width="40%"
      persistent
    >
      <VerTablasAnular
        :idToAnular="idToAnular"
        :tablasModificadas="tablasModificadas"
        @closeAndReload="closeAndReload"
        @loadDetalleProcesoAsignacion="loadDetalleProcesoAsignacion"
      ></VerTablasAnular>
    </v-dialog>
    <v-dialog
      v-if="modalVerProcesoDeta"
      v-model="modalVerProcesoDeta"
      @keydown.esc="closeModalProcesoDeta"
      max-width="65%"
      persistent
    >
      <VerDetalleProcesoAsignacion
        :detaProcesoProp="detaProcesoProp"
        @closeModalProcesoDeta="closeModalProcesoDeta"
      ></VerDetalleProcesoAsignacion>
    </v-dialog>
    <v-dialog
      v-if="modalVerTablasVigenciaPost"
      v-model="modalVerTablasVigenciaPost"
      @keydown.esc="closeAndReload"
      max-width="60%"
      persistent
    >
      <VerTablaVigenciaPosterior
        :tablasVigPost="tablasVigPost"
        @closeAndReload="closeAndReload"
      ></VerTablaVigenciaPosterior>
    </v-dialog>
    <DeleteDialog
      :titleProp="titleAnular"
      :maxWidth="'40%'"
      :confirmButtonText="'Anular'"
      :openDelete.sync="showDeleteModal"
      @onDeleteItem="anularProcesoAsignac()"
    />
  </v-container>
</template>

<script>
import { mapActions } from "vuex";
import PageHeader from "@/components/ui/PageHeader";
import GoBackBtn from "@/components/shared/GoBackBtn";
import DeleteDialog from "@/components/shared/DeleteDialog";
import VerDetalleProcesoAsignacion from "@/components/modules/prestadores/VerDetalleProcesoAsignacion.vue";
import enums from "@/utils/enums/index.js";

export default {
  name: "VerProcesoAsignacion",
  components: {
    DeleteDialog,
    VerDetalleProcesoAsignacion,
    GoBackBtn,
    PageHeader
  },
  data: () => ({
    expanded: [],
    routeToGo: "ConsultaTablaFacturacion",
    showAnularModal: false,
    verProc: enums.icons.SEE,
    modalVerProcesoDeta: false,
    searchAsig: null,
    titleAnular: "¿Desea anular el proceso seleccionado?",
    anuladoIcon: enums.icons.CIRCLE_NONE,
    searchIcon: enums.icons.SEARCH,
    registroId: null,
    modalVerTablasAnular: false,
    title: enums.titles.DETALLE_ASIGNACION,
    tablasModificadas: [],
    showDeleteModal: false,
    tablasVigPost: [],
    isAnula: null,
    modalVerTablasVigenciaPost: false,
    headers: [
      {
        text: "N° de proceso",
        value: "procAsignTablaFacId",
        sortable: false
      },

      {
        text: "Tabla nombre",
        value: "tabNom",
        sortable: false
      },
      {
        text: "Nueva vigencia",
        value: "nuevaVigencia",
        sortable: false
      },
      { text: "Acciones", value: "actions", sortable: false, align: "end" },
      { text: "", value: "data-table-expand", align: "end" }
    ],
    registrosAsignacion: []
  }),
  created() {
    // this.registroId = this.asignacionProp.idRegistro
    this.loadDetalleProcesoAsignacion();
  },
  methods: {
    ...mapActions({
      anularProcesoAsig: "prestadores/anularProcesoAsig",
      fetchProcesosAsignacionTablasFacturacion:
        "prestadores/fetchProcesosAsignacionTablasFacturacion",
      setAlert: "user/setAlert"
    }),
    async loadDetalleProcesoAsignacion() {
      this.registrosAsignacion = []
      const response = await this.fetchProcesosAsignacionTablasFacturacion();
      this.registrosAsignacion = response;
    },
    handleExpansion(item, state) {
      const itemIndex = this.expanded.indexOf(item);
      state ? this.expanded.splice(itemIndex, 1) : this.expanded.push(item);
    },
    closeAndReload() {
      this.modalVerTablasAnular = false;
      this.modalVerTablasVigenciaPost = false;
    },

    verProcesoDeta(item) {
      this.modalVerProcesoDeta = true;
      this.detaProcesoProp = item;
    },
    closeModalProcesoDeta() {
      this.modalVerProcesoDeta = false;
    },
    closeModal() {
      this.$emit("closeModalProcesoAsig");
    },
    openDialogAnulaProceso(item) {
      this.idToAnular = item.procAsignTablaFacId;
      this.showDeleteModal = true;
    },
    async anularProcesoAsignac() {
      const response = await this.anularProcesoAsig({
        procAsignTablaFacId: this.idToAnular
      });

      if (response.error == 0) {
        this.setAlert({
          type: "success",
          message: response.mensaje
        });
        this.loadDetalleProcesoAsignacion();
      } else if (response.error == 1) {
        this.tablasModificadas = response.tablasModificadas;
        this.modalVerTablasAnular = true;
        this.setAlert({
          type: "warning",
          message: response.mensaje
        });
      } else {
        this.tablasVigPost = response.tablasVigenciaPosterior;
        this.modalVerTablasVigenciaPost = true;
        this.setAlert({
          type: "warning",
          message: response.mensaje
        });
      }
      this.showDeleteModal = false;
    }
  }
};
</script>

<style scoped>
#simple-table-container {
  overflow-x: hidden;
  width: 100%;
  overflow-y: scroll;
  max-height: 250px;
  min-height: 50px;
}
.fontsize {
  font-size: 12px;
}
</style>
