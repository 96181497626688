<template>
  <v-card>
    <v-container>
      <v-card-title>
        <span class="pl-1 primary--text"
          >{{ formAsignacionTitle }} {{ this.nombreTab }}</span
        >
      </v-card-title>
      <v-card-text>
        <v-data-table
          class="elevation-1"
          :headers="headers"
          :items="itemsDetalle"
          item-key="tabId"
          :search="searchDeta"
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-row>
                <v-col cols="8">
                  <v-text-field
                    v-model="searchDeta"
                    :append-icon="searchIcon"
                    label="Buscar"
                    single-line
                    hide-details
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-toolbar>
          </template>
        </v-data-table>
        <v-row>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            outlined
            :disabled="itemsDetalle.length < 1"
            @click="exportExcel()"
            class="to-right fontsize m-2"
          >
            Exportar detalle
          </v-btn>
          <v-btn class="m-2" outlined @click="closeModal">
            Cerrar
          </v-btn>
        </v-row>
      </v-card-text>
    </v-container>
  </v-card>
</template>

<script>
import { mapActions } from "vuex";
import enums from "@/utils/enums/index.js";
import helpersExcel from "@/utils/helpers/importExportExcel";

export default {
  name: "VerDetalleProcesoAsignacion",
  props: {
    detaProcesoProp: {
      type: Object
    }
  },
  data: () => ({
    expanded: [],
    searchDeta: null,
    nombreTab: null,
    searchIcon: enums.icons.SEARCH,
    formAsignacionTitle: "Detalle del proceso",
    nomTabla: null,
    idProp: null,
    headers: [
      {
        text: "Prestador",
        value: "prestador",
        sortable: false
      },
      {
        text: "Origen",
        value: "origen",
        sortable: false
      },
      {
        text: "Agente",
        value: "agente",
        sortable: false
      },
      {
        text: "Obra social",
        value: "os",
        sortable: false
      },
      {
        text: "Plan",
        value: "plan",
        sortable: false
      },
      {
        text: "Tabla cierre",
        value: "tabIdNom",
        sortable: false
      },
      {
        text: "Cierre vigencia",
        value: "vigenciaCierre",
        sortable: false
      }
    ],
    itemsDetalle: []
  }),
  created() {
    this.idProp = this.detaProcesoProp.procAsignTablaFacId
    this.idRegistro = this.detaProcesoProp.tabId;
    this.nombreTab = this.detaProcesoProp.tabNom;
    this.loadDetalleProceso();
  },
  methods: {
    ...mapActions({
      getProcesoAsignacionTablasById:
        "prestadores/getProcesoAsignacionTablasById"
    }),
    exportExcel() {
      let result = [];
      this.itemsDetalle.forEach(x =>
        result.push({
          ["Prestador"]: x.prestador,
          ["Origen"]: x.origen,
          ["Agente"]: x.agente,
          ["Obra social"]: x.os,
          ["Plan"]: x.plan,
          ["Tabla cierre"]: x.tabIdNom,
          ["Cierre de vigencia"]: x.vigenciaCierre
        })
      );
      let formato = {
        filas: result,
        hoja: "Detalle asignación"
      };
      helpersExcel.excelExport(formato, `Detalle proceso asignación tabla '${this.nombreTab}'`);
    },
    async loadDetalleProceso() {
      const response = await this.getProcesoAsignacionTablasById(
        this.idProp
      );
      this.itemsDetalle = response;
  
      //this.nomTabla = this.itemsDetalle.TabIdNom;
    },
    closeModal() {
      this.$emit("closeModalProcesoDeta");
    },
    handleExpansion(item, state) {
      const itemIndex = this.expanded.indexOf(item);
      state ? this.expanded.splice(itemIndex, 1) : this.expanded.push(item);
    }
  }
};
</script>

<style scoped>
#simple-table-container {
  overflow-x: hidden;
  width: 100%;
  overflow-y: scroll;
  max-height: 250px;
  min-height: 50px;
}
.fontsize {
  font-size: 12px;
}
</style>
